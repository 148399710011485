.header-custom {
  /* background-color: rgba(0, 0, 0, 0.4); */
/* 
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082), inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
border-radius: 0px 0px 32px 32px; */
}

.custom-link {
  margin-right: 40px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.759);
  text-decoration: none;
  padding: 8px 0;
}
.custom-logo {
  color: white !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
}
.green {
  color: rgb(47, 255, 57);
}

.active {
  color: white !important;
  font-size: 18px;
/* 
  font-weight: 700 !important; */
}

.navbar {
  background: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082), inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082),
    inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082) !important;
  /* -webkit-backdrop-filter: blur(75.8713px) !important; */
  /* backdrop-filter: blur(75.8713px) !important; */

  box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082), inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
border-radius: 0px 0px 32px 32px;
  position: fixed;
  top: 0;
  right: 0;

  /* Note: backdrop-filter has minimal browser support */
}


.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}
.navbar-toggler {
  border:none;
}
.toggle_menue_icon svg{

  color: white;
  font-size:30px;
}
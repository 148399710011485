.card-img {
  width: 140px;
  height: 80%;

  border-radius: 24px;
}

.card-title {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 42px;
  letter-spacing: -0.04em;
  margin-top:-13px;

  color: #ffffff;
}
.card-text{
  font-size: 18px;
  font-family: var(--font-primary);
  font-style: normal;
}

@media (max-width: 900px) {
    .card-title{
      font-size: 20px;
      line-height: 35px;
    }
    .card-text{
      font-size: 16px;
    }
  }



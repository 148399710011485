.TeamContainer {
  background: rgba(0, 0, 0, 0.082) !important;
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgb(149 149 149 / 8%), inset -51.9667px 51.9667px 51.9667px rgb(255 255 255 / 8%) !important;
  /* -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important; */
  /* height: 100vh; */
  min-height: 100%;
  padding-top: 41px;
  width: 100%;
  padding-bottom: 62px;
}

.background {
  color: white;
  max-width: 90%;
  background: rgba(0, 0, 0, 0.4);
  margin: 0 auto;
  position: relative;
  padding: 20px;
  /* background-image: var(--bg-image); */
  background-size: cover;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 28px;
  border: 2px solid rgba(224, 224, 224, 1);
  box-sizing:border-box;
  
  /* filter: brightness(0.9);
  backdrop-filter: brightness(70%) !important; */

  
  

}
/* .background::before{

  content: ""; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0, 0, 0, 0.759);
  opacity: .4;
  z-index: -1;
      border-radius: 29px;


} */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 900px) {

}

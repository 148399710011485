@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&family=Spectral:wght@300&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor {
  cursor: pointer;
}
.text_primary {
  color: var(--primary_color);
  font-family: var(--font-primary);
}
.text_secondary {
  color: var(--secondary_color);
  font-family: var(--font-primary);
}
.text_bold {
 
  font-family: var(--font-primary);
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
}

:root {
  --primary_color: #ffffff;
  --secondary_color: rgba(75, 75, 75, 1);

  --primary_bg: rgb(65, 69, 63);
  --secondary_bg: #ffffff;

  --font-primary: "Poppins", sans-serif;
  --bg-image:url(https://source.unsplash.com/random/1920x1080/?nature,green,leaf,black,dark%22)
 
}

@media (max-width: 900px) {
  .text_bold {
    font-weight: 600;
    font-size: 15px;
    line-height: 30px;
  }
}


.wrapper-background-onScroll {
  background-image: var(--bg-image);
  height: 100%;
  width:100%;
  position: fixed;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
  backdrop-filter: blur(75.8713px) !important;
  -ms-overflow-style: none;  /* IE and Edge */
  /* filter: brightness(0.8); */

  scrollbar-width: none;  /* Firefox */
}
.wrapper-background-onScroll::-webkit-scrollbar {
  display: none;
}
/* .wrapper-background-onScroll::before{

  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1111111;
  opacity: 0.8;
  filter: brightness(0.5);
  -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important;
} */

.wrapper-background-hiddenScroll {
  background-image: var(--bg-image);
  min-height: 100vh;
  height: 100%;
  width:100%;
  position: fixed;
  filter: brightness(0.7);
 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: hidden;
  backdrop-filter: blur(75.8713px) !important;
  
}
.wrapper-background-hiddenScroll::before{

  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1111111;
  opacity: 0.5;
  filter: brightness(0.5);
  -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important;
  
 
}


@media (max-width: 900px) {
  .wrapper-background-hiddenScroll {
    overflow-y: scroll;
  }
}

a{
  color: rgb(212, 209, 209);
}

a:hover{
 color: white;

   
}
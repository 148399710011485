.boxTitle {

      background: rgba(0, 0, 0, 0.4);
  opacity: 0.95;
  border: 2px solid var(--primary_color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5px 50px;
  position: relative;
}
.boxTitle strong {
  font-size: 26px;
  font-weight: 600;
  text-align: center;
}

.right-before-after:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  top: 23px;
  right: -23px;
  z-index: -3;
  background-color: var(--primary_color);
}

.right-before-after:after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: 17px;
  right: -8px;
  z-index: 0;
  background-color: var(--primary_color);
}

.left-before-curcle:before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: 17px;
  left: -8px;
  z-index: 0;
  background-color: var(--primary_color);
}

@media (max-width: 900px) {
  .boxTitle strong {
    font-size: 20px;
    font-weight: 500;
  }

  .boxTitle {
   
   
    border-radius: 79px;
   
    padding: 5px 15px;
   
}

  .right-before-after:before {

    width: 15px;
    height: 2px;
    top: 20px;
    right: -22px;
  
  }
  
  .right-before-after:after {
  
    width: 13px;
    height: 13px;
  
    top: 14px;
    right: -8px;
  
  }
  
  .left-before-curcle:before {
  
    width: 13px;
    height: 13px;
  
    top: 14px;
    left: -8px;
  
  }






}
@media (max-width: 519px) {
  .boxTitle strong {
    font-size: 16px;
    font-weight: 400;
  }
}
@media (max-width: 498px) {

  .boxTitle {
   
   
    border-radius: 79px;
   
    padding: 5px 15px;
   
}
.boxTitle strong {
  font-size: 14px;
  font-weight: 400;
}

  .right-before-after:before {

    width: 22px;
    height: 2px;
    top: 18px;
    right: -25px;

}

.right-before-after:after {

  width: 10px;
  height: 10px;

  top: 14px;
  right: -6px;

}

.left-before-curcle:before {

  width: 10px;
  height: 10px;
  top: 14px;
  left: -6px;

}

}


@media (max-width: 398px) {
  .boxTitle {
   
   
    border-radius: 114px;
    padding: 3px 10px;
   
}
.boxTitle strong {
  font-size: 10px;
  font-weight: 400;
}

.right-before-after:before {

  width: 22px;
  height: 2px;
  top: 15px;
  right: -25px;

}

.right-before-after:after {

width: 10px;
height: 10px;
top: 11px;
right: -6px;

}

.left-before-curcle:before {

width: 10px;
height: 10px;
top: 11px;
left: -6px;

}



}
.roundShape {
  width: 50px;
  height: 50px;
  border: 2px solid var(--secondary_bg);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 28px;
  font-weight: 700;
}

.roundShape_active_bg {
  background: var(--secondary_bg);
  color: var(--primary_bg) !important;
}

.dot:before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  top: 16px;
  right: -7px;
  z-index: 0;
  background: var(--secondary_bg);
}
.HrWrapper {
  width: 25%;
  height: 25px;
  border-top: none;
  border-bottom: 2px dashed var(--secondary_bg);
  border-right: none;
  border-left: none;
  position: relative;
}

.rightArrow:after {
  content: "\25ba";
  /* padding-left: 0.5em; */
  position: absolute;
  right: -3px;
  top: 14px;
  color: var(--primary_color);
}

.card-content-wrapper {
  border: none;
  padding: 20px;
  background-color: transparent !important;
  color: var(--primary_color);
}

.card-content-wrapper h3 {
  font-weight: 700;
  font-size: 32px;
  font-family: var(--font-primary);
}
.card-content-wrapper p {
  font-weight: 500;
  font-size: 20px;
  font-family: var(--font-primary);
}

@media (max-width: 900px) {
  .card-content-wrapper h3 {
    font-size: 24px;
  }
  .card-content-wrapper p {
    font-weight: 500;
    font-size: 16px;
  }
}

.buble-details {
  background: rgba(0, 0, 0, 0.082) !important;
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgb(149 149 149 / 8%), inset -51.9667px 51.9667px 51.9667px rgb(255 255 255 / 8%) !important;
  /* -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important; */
  min-height: 100vh;
}

#process_content_wrapper {
  /* background-image: var(--bg-image); */
  min-height: 526px;
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(224, 224, 224, 1);

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
}

.search_fullwidth {
  height: 83vh;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* align-items: center; */
  margin-top: 14vh;
  -webkit-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

.col-md-8 {
  -webkit-transition: all 0.8s ease-in-out !important;
  -o-transition: all 0.8s ease-in-out !important;
  transition: all 0.8s ease-in-out !important;
}

.Home_wrapper {
  display: none;
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s ease-out;
  -o-transition: visibility 0s, opacity 0.5s ease-out;
  transition: visibility 0s, opacity 0.5s ease-out;
}

/* #Home_page{

     background-image: url("https://source.unsplash.com/random/1920x1080/?nature,green,leaf,black,dark");
     height: 100%;

   
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
    
} */

.margin-top-114 {
  margin-top: -114px;
  min-height: 93vh;
  max-height: auto;
}
.margin-top-65 {
  margin-top: -65px;
  min-height: 90vh;
  max-height: auto;
}

.Contactcontainer {
  background: rgba(0, 0, 0, 0.082) !important;
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgb(149 149 149 / 8%),
    inset -51.9667px 51.9667px 51.9667px rgb(255 255 255 / 8%) !important;
  /* -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important; */
  min-height: 100%;
  padding-top: 41px;
  width: 100%;
  padding-bottom: 62px; 
  /* min-height: 100vh; */
}

.background {
  background: rgba(0, 0, 0, 0.4);
  color: white;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  /* background-image: var(--bg-image); */
  background-size: cover;
  margin-bottom: 10px;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  border-radius: 30px;
  /* filter: brightness(0.9);
  backdrop-filter: brightness(70%) !important; */
}
/* .background::after{

  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1111111;
  opacity: 0.8;
  filter: brightness(0.5);
  -webkit-backdrop-filter: blur(75.8713px) !important;
  backdrop-filter: blur(75.8713px) !important;


} */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
  .Contactcontainer {
    height: auto;
   
  }
}

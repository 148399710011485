.contact-left-header {
font-family: var(--font-primary);
  font-style: normal;
  font-weight: bold;
  font-size: 29px;

  letter-spacing: -0.04em;

  color: #ffffff;
}

.contact-left-paragraph {
  font-family: var(--font-primary);
 
  font-size: 20px;

}
.contact-info {
  font-family: var(--font-primary);
 
 

}
.contact-info p{

 
  font-size: 20px;

}
.contact-info span{

 
  font-size: 17px;

}


@media (max-width: 900px) {
  .contact-left-header {
    font-size: 20px;
  }
  .contact-left-paragraph {
    font-size: 16px;
  
  }
}

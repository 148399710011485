.btn-custom {
     /* background-color: var(--primary_bg); */
     border-radius: 24px;
     display: block;
     margin: auto;
     /* padding: 1px 19px; */
     width: 281px;
     height: 65px;
     cursor: pointer;
     background: rgba(255, 255, 255, 1);
     /* box-shadow: inset 51.9667px -51.9667px 51.9667px rgb(149 149 149 / 8%), inset -51.9667px 51.9667px 51.9667px rgb(255 255 255 / 8%); */
     /* -webkit-backdrop-filter: blur(75.8713px);
     backdrop-filter: blur(75.8713px); */
     font-family: var(--font-primary);
     font-style: normal;
     font-weight: bold;
     font-size: 28px;
     margin-top: 22px;
     color: rgba(74, 74, 74, 1);
     font-weight: 800;
     font-size: 24px;
}
.form-control{
  background-color: var(--secondary_bg);
  border-radius: 16px;
}
.contract-form-container{

   width: 80%;
   margin: 0 auto;
}

@media (max-width: 900px) {
  .btn-custom {
    border-radius: 24px;
    display: block;
    margin: auto;
    padding: 17px 30px;
    width: 200px;
    height: 76px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.082);
    backdrop-filter: blur(75.8713px);
    font-size: 18px;
  }
  .contract-form-container{

    width: 100%;
    margin: 0 auto;
 }
}

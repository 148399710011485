.content-container {
  background: rgba(0, 0, 0, 0.4);
border: 2px solid #E0E0E0;
border-radius: 24px;
  -webkit-box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082),
    inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082),
    inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
  /* -webkit-backdrop-filter: blur(75.8713px);
  backdrop-filter: blur(75.8713px); */
  /* Note: backdrop-filter has minimal browser support */

  overflow-wrap: break-word;
 
  padding: 15px 30px;
  box-sizing: border-box;
}
.content-container h5 {
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0;
}

.box_card_home_page p {
  font-size: 18px;
  font-family: var(--font-primary);
  line-height: 39.5px;
 
  
  text-align: center;
}

.box_card_home_page h5 {
  font-size: 26px;
  font-weight: 700;
  font-family: var(--font-primary);
  text-transform: capitalize;
}

@media (max-width: 900px) {
  .box_card_home_page p {
    font-size: 16px;
  }

  .box_card_home_page h5 {
    font-size: 20px;
  }
}

.heading {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  padding: 20px 10px;
  font-weight: 700;
  color: var(--primary_color);
  margin-top: 21px;
  /* line-height: 72px; */
  letter-spacing: -0.02em;
  /* filter: brightness(1); */
  text-shadow: 0px 0px 250px #000000, 0px 4px 20px rgb(0 0 0 / 25%), 0px 0px 56px #000000;
}

@media (max-width: 900px) {
  .heading {
    font-size: 28px;
  }
}
@media (max-width: 498px) {
  .heading {
    font-size: 20px;
  }
}

* {
  outline: none;
}

.tb {
  display: table;
  width: 100%;
}

.td {
  display: table-cell;
  vertical-align: middle;
}

#cover input,
button {
  color: rgb(255, 255, 255);
  font-family: Nunito;
  padding: 0 10px;
  margin: 0;
  border: 0;
  background-color: transparent;
}

#cover {
  
  border-radius: 24px;
 
 
}
.custom_shadow{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
   
}
.border-none{

 border: none;
}
.bg-transfarent{
  background-color: transparent;
}
.bg-white{
  background-color: white;

}
.border{

 border: 1px solid #c4c4c4;
}

#cover input[type="text"] {
  width: 100%;
  color: gray;
 font-family: var(--font-primary);
  line-height: 1;
  font-size: 18px;
    padding-left: 22px;
}

#cover input[type="text"]::-webkit-input-placeholder {
  color: gray;
}

#cover input[type="text"]::-moz-placeholder {
  color: gray;
}

#cover input[type="text"]:-ms-input-placeholder {
  color: gray;
}

#cover input[type="text"]::-ms-input-placeholder {
  color: gray;
}

#cover input[type="text"]::placeholder {
  color: gray;
}

#s-cover {
  width: 1px;
  padding-left: 35px;
}

#cover button {
  position: relative;
  display: block;
  width: 84px;
  height: 40px;
  cursor: pointer;
}

#s-circle {
  position: relative;
  top: -8px;
  left: 0;
  width: 43px;
  height: 43px;
  margin-top: 0;
  border-width: 15px;
  border: 15px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

#cover .searchIcon {
  font-size: 30px;
  color: #c4c4c4;
}



.search-loader-container{
  height: 40vh;

}
.search-loader-container .outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: white;
  border-right-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

#search_result_page{
  

}
.tagContainer {
  padding: 8px 27px;
  border: 2px solid #E5E5E5;
  border-radius: 24px;
  margin-bottom: 10px;
  margin: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.12);
}

/* #search_result_page {
  background-image: url("https://source.unsplash.com/random/1920x1080/?nature,green,leaf,black,dark");
  height: 100vh;


  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

.search_result_left_wrapper .summary-details {
  font-size: 16px;
  font-weight: 500;
}
.search_result_left_wrapper .summary_title {
  font-size: 20px;
  font-weight: 700;
}

.search_result_grid_card_wrapper {
  background: #FCFCFC;
  -webkit-box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 56px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 56px rgba(0, 0, 0, 0.15);
  /* -webkit-backdrop-filter: blur(75.8713px);
  backdrop-filter: blur(75.8713px); */
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 24px;
  border: 5px solid transparent;
  padding: 15px 30px;
  box-sizing: border-box;
}
.firefox_result_card_style{

  background: hsla(0, 0%, 100%, .3);
  position: relative;
}
.firefox_result_card_stylemain::before {
  content: '';
  margin: -35px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: blur(20px);
  z-index: -1;
}

.pagination_container{

  background: white;
  -webkit-box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082),
    inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
  box-shadow: inset 51.9667px -51.9667px 51.9667px rgba(149, 149, 149, 0.082),
    inset -51.9667px 51.9667px 51.9667px rgba(255, 255, 255, 0.082);
  -webkit-backdrop-filter: blur(75.8713px);
  backdrop-filter: blur(75.8713px);
  /* Note: backdrop-filter has minimal browser support */
    color:white;
  border-radius: 24px;
  border: none;
  padding: 15px 30px;
}

.search-right-content{

  background: #FCFCFC;
  -webkit-box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 56px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.15), 0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 56px rgba(0, 0, 0, 0.15);
  top: 26%;
  position: absolute;
  border-radius: 24px 0px 0px 24px;
  padding: 0px 0px 28px 25px;

}

.activeBox{

  border: 5px solid rgba(80, 93, 215, 1);
}
@media (max-width: 960px) {
  .search-right-content{

    margin-top: 30px;
   
    position: initial;
    border-radius: 24px;
  
  }
}